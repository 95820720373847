.menu {
    &-logo {
        height: 30px;
    }

    &-links {
        a {
            text-decoration: none;
            color: $color-gray;
            transition: 0.5s ease color;

            &:hover {
                color: $color-blue;
            }

            @include media-breakpoint-up(md) {
                padding: 0 2rem;
            }
        }
    }

    &-popup {
        background-color: $color-white;
        overflow: hidden;
        transition: height 200ms;
        opacity: 0;
        height: 0;

        .menu-links {
            text-align: center;
            padding: 1rem 0;

            a {
                display: block;
                padding: 0.5rem 0;
            }
        }

        &-open {
            height: 100%;
            opacity: 1;
        }
    }
}
