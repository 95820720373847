.section {
    border-radius: 0px 0px 20px 20px;
    overflow: hidden;
    padding: 4rem 0;
    margin-bottom: 2rem;

    @include media-breakpoint-up(md) {
        margin-bottom: 8rem;
        padding: 6rem 0;
    }

    img {
        border-radius: 20px;
    }

    &-header {
        margin-bottom: 4rem;
    }

    &-intro {
        background: transparent linear-gradient(299deg, #009cde 0%, #caeaf835 60%, #ffffff00 100%) 0% 0% no-repeat
            padding-box;
        min-height: 50vh;

        &-text {
            max-width: 400px;
            margin: 0;
        }
    }

    &-list {
        text-align: justify;

        &-item {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                margin-right: 1rem;

                @include media-breakpoint-up(md) {
                    margin-right: 2rem;
                }
            }

            p {
                margin: 0;
            }

            &:not(:last-child) {
                margin-bottom: 2rem;
            }
        }
    }

    &-cards {
        position: relative;
        background: #f2f9ff;
        z-index: 1;
        overflow: inherit;

        &:before {
            position: absolute;
            content: "" !important;
            top: 0;
            left: 0;
            width: 100%;
            height: 120%;
            background: url("../images/ASX-Welle.svg");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            opacity: 0.3;
            z-index: -1;
            transform: translateY(-10%);
        }
    }

    &-card {
        position: relative;
        height: 200px;
        background: transparent linear-gradient(299deg, #009cde 0%, #caeaf8 150%, #ffffff 100%) 0% 0% no-repeat
            padding-box;
        border-radius: 20px;

        &.orange {
            background: transparent linear-gradient(299deg, #f3a30f 0%, #caeaf8 150%, #ffffff 100%) 0% 0% no-repeat
                padding-box;
        }

        &.green {
            background: transparent linear-gradient(299deg, #00a49a 0%, #caeaf8 150%, #ffffff 100%) 0% 0% no-repeat
                padding-box;
        }

        &.yellow {
            background: transparent linear-gradient(299deg, #d4d100 0%, #caeaf8 150%, #ffffff 100%) 0% 0% no-repeat
                padding-box;
        }

        &-header {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            transition: all 0.5s ease;
            visibility: visible;
            opacity: 1;
            transform: translateY(0);

            h3 {
                font-weight: bold;
                margin: 0;
                color: $color-white;
            }
        }

        &-body {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            text-align: center;
            padding: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            visibility: hidden;
            opacity: 0;
            transform: translateY(10px);
            transition: all 0.5s ease;

            p {
                margin: 0;
                color: $color-white;
            }
        }

        &:hover,
        &:focus {
            cursor: pointer;

            .section-card-header {
                visibility: hidden;
                opacity: 0;
                transform: translateY(10px);
            }

            .section-card-body {
                visibility: visible;
                opacity: 1;
                transform: translateY(0);
            }
        }
    }
}
