//COLORS
$color-black: #000000;
$color-white: #ffffff;
$color-gray: #2D2D2D;
$color-blue: #009CDE;

//TYPOGRAPHY
$font-family-base : "TT Norms";
$font-size-base: 1.2rem;

//LINKS
$link-color: $color-blue;

$primary: $color-blue;
$btn-color: $color-white;
$input-border-color: #e5e5e5;
$input-border-radius: 0.7rem;