.footer {
    background: $color-blue;
    padding: 3rem 0;

    &-logo {
        height: 30px;
    }

    a, p, h5 {
        color: $color-white;
        text-decoration: none;
    }
}